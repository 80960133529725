import sessionApi from "../../api/session";
import * as mutationTypes from "../mutation-types";
import router from "../../routes/index";

const state = {
  currentDoctor: null,
  loggedIn: false,
  timeoutApproaching: false,
  baseTime: new Date().getTime(),
  passedMsec: 0,
  emailLoginEnabled: false
};


const getters = {
  loggedIn: state => state.loggedIn,
  loginAction: state => state.loginAction,
  currentDoctor: state => state.currentDoctor,
  timeoutApproaching: state => state.timeoutApproaching,
  baseTime: state => state.baseTime,
  passedMsec: state => state.passedMsec,
  emailLoginEnabled: state => state.emailLoginEnabled
};


const actions = {
  login({ commit, dispatch }, params) {
    return new Promise((resolve) => {
      sessionApi.login(loggedIn => {
        let loginAction = true;
        commit(mutationTypes.ASSIGN_LOGGED_IN, { loggedIn });
        if( loggedIn == true ){
          dispatch("fetchCurrentDoctor").then(()=> {
            if(params.redirectPath){
              router.push(params.redirectPath);
            } else {
              // 権限によりログイン先が変わる
              router.push(state.currentDoctor.homeUrl);
            }
          });
        }
        resolve();
      }, params);
    });
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      sessionApi.logout(loggedIn => {
        commit(mutationTypes.ASSIGN_LOGGED_IN, { loggedIn });
        let currentDoctor = null;
        commit(mutationTypes.ASSIGN_CURRENT_DOCTOR, { currentDoctor });
        router.push("/login");
        resolve();
      });
    });
  },

  checkLoggedIn({ commit, dispatch }) {
    return new Promise((resolve) => {
      sessionApi.checkLoggedIn(loggedIn => {
        commit(mutationTypes.ASSIGN_LOGGED_IN, { loggedIn });
        if(loggedIn){
          dispatch("fetchCurrentDoctor");
        }
        resolve();
      });
    });
  },

  fetchCurrentDoctor({ commit }) {
    return new Promise((resolve) => {
      sessionApi.fetchCurrentDoctor(currentDoctor => {
        commit(mutationTypes.ASSIGN_CURRENT_DOCTOR, { currentDoctor });
        if (currentDoctor.id > 0) {
          let loggedIn = true;
          commit(mutationTypes.ASSIGN_LOGGED_IN, { loggedIn });
        }
        resolve();
      });
    });
  },

  calcDiff({ commit }){
    commit(mutationTypes.CALC_DIFF_PASSED_MSEC);
  },

  passedMsecReset({ commit }){
    commit(mutationTypes.RESET_PASSED_MSEC);
  },

  noticeTimeoutApproaching({ commit }){
    commit(mutationTypes.NOTICE_TIMEOUT_APPROACHING_ALERT);
  },

  clearTimeoutApproaching({ commit }){
    commit(mutationTypes.CLEAR_TIMEOUT_APPROACHING_ALERT);
  },

  fetchAuthSetting({ commit }) {
    return new Promise((resolve) => {
      sessionApi.fetchAuthSetting(setting => {
        let emailLoginEnabled = setting.email_login_enabled;
        commit(mutationTypes.EMAIL_LOGIN_ENABLED, { emailLoginEnabled });
        resolve();
      });
    });
  },
};


const mutations = {
  [mutationTypes.ASSIGN_LOGIN_ACTION](state, { loginAction }) {
    state.loginAction = loginAction;
  },

  [mutationTypes.ASSIGN_LOGGED_IN](state, { loggedIn }) {
    state.loggedIn = loggedIn;
  },

  [mutationTypes.ASSIGN_CURRENT_DOCTOR](state, { currentDoctor }) {
    state.currentDoctor = currentDoctor;
  },

  [mutationTypes.CALC_DIFF_PASSED_MSEC](state) {
    state.passedMsec = new Date().getTime() - state.baseTime;
  },

  [mutationTypes.RESET_PASSED_MSEC](state) {
    state.baseTime = new Date().getTime();
    state.passedMsec = 0;
  },

  [mutationTypes.NOTICE_TIMEOUT_APPROACHING_ALERT](state) {
    state.timeoutApproaching = true;
  },

  [mutationTypes.CLEAR_TIMEOUT_APPROACHING_ALERT](state) {
    state.timeoutApproaching = false;
  },

  [mutationTypes.EMAIL_LOGIN_ENABLED](state, { emailLoginEnabled }) {
    state.emailLoginEnabled = emailLoginEnabled;
  }
};


export default {
  state,
  getters,
  actions,
  mutations
};
