import chatLogApi from "../../api/chat_logs";
import * as mutationTypes from "../mutation-types";

const state = {
  chatLogs: [],
  hasUnreadMessages: false,
  latestMessageId: 0,
};

const getters = {
  chatLogs: state => state.chatLogs,
  hasUnreadMessages: state => state.hasUnreadMessages,
  latestMessageId: state => state.latestMessageId,
};

const actions = {
  fetchChatLogs({ commit }, params) {
    return new Promise((resolve, reject) => {
      chatLogApi.fetchChatLogs(chatLogs => {
        commit(mutationTypes.ASSIGN_CHAT_LOGS, { chatLogs });
        resolve();
      },
      () => {
        reject();
      }, params);
    });
  },

  addChatLog({ commit }, chatLog ){
    return new Promise((resolve, reject) => {
      commit(mutationTypes.ADD_CHAT_LOG, { chatLog });
      resolve();
    });
  },

  markAsRead({ commit }, params) {
    return new Promise((resolve, reject) => {
      chatLogApi.markAsRead(() => {
        // ログの再取得
        chatLogApi.fetchChatLogs(chatLogs => {
          commit(mutationTypes.ASSIGN_CHAT_LOGS, { chatLogs });
        } ,params);
        resolve();
      }, params);
    });
  },

  post({ commit }, params) {
    return new Promise((resolve, reject) => {
      chatLogApi.post(() => {
        resolve();
      },
      (error_message) => {
        reject(error_message);
      }, params);
    });
  },

  clearChatLogs({ commit }) {
    let chatLogs = [];
    commit(mutationTypes.ASSIGN_CHAT_LOGS, { chatLogs });
  }
};

const mutations = {
  [mutationTypes.ASSIGN_CHAT_LOGS](state, { chatLogs }) {
    state.chatLogs = chatLogs["messages"];
    state.hasUnreadMessages = chatLogs["hasUnreadMessages"];
    if(state.chatLogs != null && state.chatLogs.length > 0) {
      state.latestMessageId = state.chatLogs.slice(-1)[0]["id"];
    }
  },
  [mutationTypes.ADD_CHAT_LOG](state, { chatLog }) {
    state.chatLogs.push(chatLog);
    state.latestMessageId = chatLog["id"];
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
