import axios from "./index";

export default {
  fetchEpdsAnswers(callback, errorsCallback, params) {
    axios.get("/api/v1/epds_answers", {
      params: {
        user_id: params.user_id,
      },
      cancelToken: params.cancelToken
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
      errorsCallback();
    });
  },
  findEpdsAnswer(callback, params) {
    axios.get("/api/v1/epds_answers/" + params.epdsId).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },
  fetchEpdsUrl(callback, params) {
    axios.get("/api/v1/epds/url", {
      params: {
        department_type: params.department_type
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },
};
