<template>
  <v-container
    fluid
    class="pa-0 pr-4"
  >
    <v-row
      class="karte mt-0"
      justify="center"
    >
      <!-- カルテ情報 左カラム -->
      <v-col
        cols="12"
        sm="12"
        md="12"
        class="pt-0"
      >
        <v-textarea
          id="input-complaint"
          v-model="karte.complaint"
          variant="outlined"
          name="complaint"
          required
          label="主訴（必須）*"
          rows="2"
          :readonly="!active"
          class="pt-1 pb-0"
        />
        <v-textarea
          v-model="karte.interview"
          variant="outlined"
          name="interview"
          label="追加問診など"
          rows="5"
          :readonly="!active"
          class="pt-1 pb-0"
        />
        <v-textarea
          v-if="this.karte.diagnosis"
          v-model="karte.diagnosis"
          variant="outlined"
          name="diagnosis"
          required
          label="旧 暫定診断"
          rows="2"
          :readonly="true"
          class="pt-1 pb-0"
        />
        <midwife-diagnosis
          v-if="this.reservation.department_type === 3"
          v-model:karte="this.karte"
          :active="active"
        />
        <diagnosis
          v-else
          v-model:karte="this.karte"
          :active="active"
          :department-data="String(this.reservation.department_type)"
        />
        <v-textarea
          id="input-guidance"
          v-model="karte.guidance"
          variant="outlined"
          name="guidance"
          required
          label="指導内容（必須）*"
          rows="5"
          :readonly="!active"
          class="pt-1 pb-0"
        />
        <v-select
          v-model="karte.outcome_id"
          variant="outlined"
          name="outcome"
          :items="outcomeOptions"
          item-title="key"
          item-value="value"
          required
          :readonly="!active"
          label="転帰（必須）*"
        />
        <v-select
          v-model="karte.tool_id"
          variant="outlined"
          name="tool"
          :items="toolOptions"
          item-title="key"
          item-value="value"
          required
          :readonly="!active"
          label="実際のツール（必須）*"
        />
        <v-textarea
          v-model="karte.message"
          variant="outlined"
          name="message"
          hint="※相談員全員に周知したいことは特記事項へ記入をお願いします。
          ※「申し送り」は次の担当者のみに伝えたいことを記載してください。"
          label="次の人への申し送り（既往歴などは特記事項へ）"
          rows="2"
          :readonly="!active"
          class="pt-1 pb-0 multiline-hint"
        />
        <v-checkbox
          v-model="karte.is_connected"
          color="pink"
          :readonly="!active"
          :class="!active && karte.is_connected ? 'disable-connected' : ''"
          label="時間内相談なし"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import outcomeOptions from "../../mixins/outcome_options.js";
import Diagnosis from "./Diagnosis.vue";
import MidwifeDiagnosis from "./MidwifeDiagnosis.vue";

export default {
  name: "KarteBody",
  components: {
    Diagnosis,
    MidwifeDiagnosis
  },
  mixins: [outcomeOptions],
  props: {
    active: Boolean
  },
  data: () => ({
    // jsonBuilderで値をto_iしているので未設定は0
    toolOptions: [
      { key: "未設定", value: 0 },
      { key: "LINE メッセージ", value: 1 },
      { key: "LINE 音声通話", value: 2 },
      { key: "LINE ビデオ通話", value: 3 },
      { key: "電話", value: 4 }
    ],
    movieDialog: false,
    movie: ""
  }),
  computed: {
    ...mapGetters([
      "karte",
      "reservation",
    ]),
  },
  methods: {
    ...mapActions(["updateKarte"]),
    outcomeOptionName: function(id){
      if(!id) return null;
      return this.outcomeOptions.find(option => option.value == id).key;
    }
  },
};
</script>

<style lang="scss">
.karte{
  .cooperated-area {
    border: 2px solid rgb(233, 30, 99);
    border-radius: 5px;
    padding: 5px 10px;
    background-color: rgb(253, 238, 243);
  }

  .cooperated-area div.v-card {
    background: transparent;
  }
  .cooperated-area  .v-theme--light.v-text-field--outlined>.v-input__control>.v-input__slot {
    background-color: white!important;
  }

  /* vuetify3 対応 入力不可のラベルの透明度を1にする */
  .v-selection-control--disabled,
  .v-field--disabled {
    --v-disabled-opacity: 1 !important;
  }
}

/* カルテ情報 override */
/* テキストエリア/セレクトボックス ラベル色 */
.karte .v-field-label {
  color: black;
}
/* テキストエリア/セレクトボックス 枠 */
.karte .v-text-field--outlined:not(.v-input--is-focused) fieldset,
.karte .v-text-field--outlined:not(.v-input--is-focused) .v-field__field:hover fieldset {
  border: 2px solid #C0C0C0 !important;
}
/* 時間内連絡なしチェックONの場合のみ適用されるラベル色 */
.disable-connected label.v-label {
  color: #212121 !important;
  opacity: 1;
}
/* 編集エリアの背景色 */
.karte div.v-input.v-text-field:not(.v-input--readonly) .v-field__field,
.karte div.v-input.v-text-field:not(.v-input--readonly) .v-field__overlay {
  background-color: #E8F2FF !important;
}
/* 暫定診断（編集中）の背景色 */
.karte .diagnosis-form {
  div.v-input.v-text-field:not(.v-input--disabled) .v-field__field,
  div.v-input.v-text-field:not(.v-input--disabled) .v-field__overlay {
    background-color: #E8F2FF !important;
  }
  .v-input--disabled.v-text-field .v-field__field label {
    color: black !important;
  }
}
/** チャット入力欄の背景色が上記スタイルに影響されるため強制的に白に */
#chat-input {
  div.v-input.v-text-field:not(.v-input--readonly) .v-field__field {
    background-color: #FFF !important;
  }
  /** チャット文字入力域がdisabledのときの placeholderの文字色 */
  div.v-input.v-input--disabled .v-field__field textarea {
    color: #C0C0C0 !important;
  }
}
/** 編集不可時の画像アップロードボタンの背景色 */
.karte .upload.v-theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #CCC !important;
}
/* 編集時以外はフォーカスされてもヒントを表示させないようにする */
.karte div.v-input.v-text-field.v-input--readonly .v-input__details{
  visibility: hidden;
}
/* Upgrade対応 vietify1.5と見た目が変わらないよう marginを変更 */
.karte .v-textarea.v-text-field--outlined textarea {
  margin-top: 24px !important;
  /* outlineの枠線に重なるため */
  margin-right: 2px;
  margin-bottom: 2px;
}
.karte .v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections{
  padding-top: 24px;
}
.karte .v-input__slot legend {
  width: 0px !important;
}
.karte .v-text-field--outlined .v-label--active {
  max-width: 133%;
  -webkit-transform: translateY(-6px) scale(.75);
  transform: translateY(-6px) scale(.75);
}

/* LINE貼り付け labelの文字列改行 */
.karte .v-input.v-text-field.linefeed-label .v-label {
  white-space: pre-line;
}
.karte .v-input.v-text-field.linefeed-label textarea {
  margin-top: 42px !important;
}

/* 暫定診断 モーダル */
.modal-content-wrapper {
  font-size: 14px;
}

/* 相談なしチェックボックスラベルの位置調整 */
.v-label.v-label--clickable {
  margin-bottom: 0px !important;
}

/* v-textarea の hint を複数行で表示させる */
.multiline-hint {
  .v-messages {
    white-space: pre-line;
  }
}
</style>
