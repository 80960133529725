<template>
  <div>
    <v-row class="ma-0 mb-1 pt-1">
      <v-col
        v-show="!daytimeAdviceSheet.noAdvice"
        cols="10"
        class="daytime-advice-date-picker px-0"
      >
        <v-menu
          ref="dateMenu"
          v-model="dateMenu"
          min-width="auto"
          transition="fade-transition"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <VueDatePicker
              v-model="daytimeAdviceSheetValue.childBirthdate"
              placeholder="児の生年月日または出産予定日"
              locale="jp"
              format="yyyy-MM-dd"
              model-type="yyyy-MM-dd"
              :min-date="minDate"
              :max-date="maxDate"
              first-day-of-week="1"
              :readonly="!editing || daytimeAdviceSheet.noAdvice == null"
              :disabled="!editing || daytimeAdviceSheet.noAdvice == null"
              :teleport="true"
              :enable-time-picker="false"
              :clearable="false"
              :class="editing && daytimeAdviceSheet.noAdvice == false ? 'dp_active' : ''"
              auto-apply
            />
          </template>
        </v-menu>
      </v-col>
      <v-col
        v-show="daytimeAdviceSheet.noAdvice == false"
        cols="2"
        align-self="center"
        class="px-1"
      >
        <v-btn
          v-if="editing"
          color="pink"
          theme="dark"
          :disabled="!editing || daytimeAdviceSheet.noAdvice != false"
          @click="calcAge"
        >
          計算する
        </v-btn>
      </v-col>
    </v-row>
    <v-text-field
      v-if="daytimeAdviceSheetValue"
      v-show="!daytimeAdviceSheet.noAdvice"
      id="child_age"
      v-model="daytimeAdviceSheetValue.childAge"
      variant="outlined"
      name="childAge"
      required
      label="児の年齢または妊娠週数"
      readonly
      :disabled="!editing || daytimeAdviceSheet.noAdvice != false"
      class="pt-1 pb-0"
      @click="open"
    />

    <v-dialog
      v-model="viewModal"
      name="selectChildAge"
      width="auto"
    >
      <div
        class="panel"
        @click.stop
      >
        <div class="modal-header">
          <h3>児の年齢または妊娠週数</h3>
          <span
            class="close"
            @click="close"
          >X</span>
        </div>
        <div class="panel-body">
          <div class="panel-box">
            <h4>＜妊娠週数＞</h4>
            <ul class="item-list">
              <li v-for="week in weeks">
                <span @click="onSelectWeeksOfPregnancy(week)">妊娠{{ week }}週</span>
              </li>
            </ul>
          </div>
          <div class="panel-box">
            <h4>＜児の年齢＞</h4>
            <div class="sub_category">
              <ul class="item-list">
                <li v-for="month in months">
                  <span @click="onSelectMonthOfBaby(month)">{{ month }}ヶ月</span>
                </li>
              </ul>
              <ul class="item-list">
                <li v-for="month in months">
                  <span @click="onSelectYearMonthOfBaby(1, month)">1歳{{ month }}ヶ月</span>
                </li>
              </ul>
              <ul class="item-list">
                <li v-for="year in years">
                  <span @click="onSelectChildAge(year)">{{ year }}歳</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="panel-box">
            <h4>＜該当なし/未定＞</h4>
            <div class="sub_category">
              <ul class="item-list">
                <li>
                  <span
                    class="text-no-wrap"
                    @click="onSelectNotApplicable"
                  >該当なし/未定</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DaytimeAdviceChildAge",
  model: {
    prop: "daytimeAdviceSheet"
  },
  props: {
    editing: Boolean,
    daytimeAdviceSheet: Object,
    modelValue: Object,
  },
  data: () => ({
    weeks: Array.from({length: 39}, (_, i) => i + 4), // 4週〜42週
    months: [...Array(12).keys()], // 0〜11か月
    years: Array.from({length: 14}, (_, i) => i + 2), // 2〜15歳
    dateMenu: false,
    viewModal: false,
  }),
  watch: {
    dateMenu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    ...mapGetters([
      "autoCalcChildAge",
    ]),
    minDate: function() {
      // 今から15年前
      var date = new Date();
      date.setFullYear(date.getFullYear() - 15 );
      return date.toISOString().substr(0, 10);
    },
    maxDate: function() {
      // 今から1年後
      var date = new Date();
      date.setFullYear(date.getFullYear() + 1 );
      return date.toISOString().substr(0, 10);
    },
    daytimeAdviceSheetValue:function() {
      return this.daytimeAdviceSheet;
    },
  },
  methods: {
    onSelectWeeksOfPregnancy(week){
      this.daytimeAdviceSheetValue.childAge = "妊娠" + week + "週";
      this.daytimeAdviceSheetValue.childAgeNotApplicable = false;
      this.close();
    },
    onSelectMonthOfBaby(month) {
      this.daytimeAdviceSheetValue.childAge = month + "ヶ月";
      this.daytimeAdviceSheetValue.childAgeNotApplicable = false;
      this.close();
    },
    onSelectYearMonthOfBaby(year, month) {
      this.daytimeAdviceSheetValue.childAge = year + "歳" + month + "ヶ月";
      this.daytimeAdviceSheetValue.childAgeNotApplicable = false;
      this.close();
    },
    onSelectChildAge(year) {
      this.daytimeAdviceSheetValue.childAge = year + "歳";
      this.daytimeAdviceSheetValue.childAgeNotApplicable = false;
      this.close();
    },
    onSelectNotApplicable() {
      this.daytimeAdviceSheetValue.childAge = "該当なし/未定";
      // 該当なし/未定を選択した場合は 児の生年月日または出産予定日 の入力値をクリアする
      this.daytimeAdviceSheetValue.childBirthdate = null;
      // 該当なし/未定を選択した状態を保持
      this.daytimeAdviceSheetValue.childAgeNotApplicable = true;
      this.close();
    },
    open () {
      this.viewModal = true;
    },
    close () {
      this.viewModal = false;
    },
    saveDate (date) {
      this.$refs.dateMenu.save(date);
    },
    calcAge() {
      // 月齢計算したタイミングで「該当なし/未定」の選択をクリアしたとみなす
      this.daytimeAdviceSheetValue.childAgeNotApplicable = false;
      this.$store.dispatch("calcChildAge", {
        birthdate: this.daytimeAdviceSheetValue.childBirthdate
      }).then(() => {
        this.daytimeAdviceSheetValue.childAge = this.autoCalcChildAge;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.panel {
  min-width: 950px;
  min-height: 500px;
  background: #fff;
  padding: 0;

  .modal-header {
    position: relative;
    width: 100%;
    background-color: #e0e0e0;
    padding: 5px;
    margin-bottom: 10px;
    text-align: center;

    h3 {
      display: inline;
      margin-right: 5px;
    }
    .close {
      position: absolute;
      font-size: 150%;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }
  }
}

.panel-body {
  padding: 5px 20px;
  display: flex;
  flex-direction: row;
}

.panel-box {
  margin: 10px;
  height: 350px;
}

.panel-box:nth-child(1) {
  width: 50%;
}

.panel-box:nth-child(2) {
  width: 30%;
}

.panel-box:nth-child(3) {
  width: 20%;
}

.sub_category {
  display: flex;
  flex-direction: row;
}

.item-list {
  height: 390px;
  margin-top: 5px;
  padding: 5px;
  padding-left: 24px;
  display: flex;
  flex-flow: column wrap;

  li {
    margin-bottom: 3px;

    span {
      color: #4372c4;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

:deep(.dp__input) {
  min-height: 56px;
  --dp-disabled-color-text: black !important;
}
</style>

<style lang="scss">
/* 日付選択の背景色 */
.daytime-advice-date-picker {
  ::placeholder {
    color: black !important;
    opacity: 1;
  }
  .dp_active {
    --dp-border-color-hover: rgb(82, 82, 82);
    .dp__input {
      background-color: #E8F2FF !important;
      border-color: rgb(118, 118, 118, 0.6);
    }
  }
  .dp__disabled {
      background: transparent;
      border-color: rgb(0, 0, 0, 0.25);
      color: rgba(0, 0, 0);
  }
}
</style>
